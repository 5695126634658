import React from "react";
import { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Profile from '../../assets/Images/technician.png';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../BookingForm/components/Calender/Calender.css';
import '../../assets/css/Booking.css';
import moment from "moment";
import { getstafflist } from "../../Api/frontbooingApi";
import { getstaffTime } from "../../Api/frontbooingApi";
import { BookingContext } from "./BookingContext";
import { TbListCheck } from "react-icons/tb";
import { getstaffslotdatabyzone } from "../../Api/frontbooingApi";
import { BUFFER_TIME_STAFF } from "../../Api/config";

const Step2 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, technicianList, setTechnicianList, expectedprice, setExpectedprice, expectedduration, setExpectedduration, zipcode, setZipcode] = useContext(BookingContext);

    const [stafflist, setStaffList] = useState([]);
    const [staffid, setStaffid] = useState(0);
    const [value, onChange] = useState();
    const [availableTime, setavailableTime] = useState([]);
    const [selectedSlotid, setSelectedSlotid] = useState(0);

    const [availableTimerandom, setavailableTimeRandom] = useState([]);
    const [randomtech, setRandomtech] = useState(true);
    const [showavailable, setShowavailable] = useState(false);
 

    const getstaff = () => {
        getstafflist().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setStaffList(apiResponse.data);
            } else {
                alert("Something went wrong");
            }
        });
    };

    useEffect(() => {
        getstaff();
    }, []);

    const selectstaff = (id) => {
        setStaffid(id);
        setChoosetechnician(id)
    }

    useEffect(() => {
        getstaffavailability(value);
    }, [staffid]);

    const getstaffavailability = (e) => {
        const date = moment(e).format("YYYY-MM-DD");
        setChoosedate(date);
        getstaffTime(date, staffid).then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                if (apiResponse.data.length > 0) {
                    getavailableTimeSlots(apiResponse.data, date);
                } else {
                    getavailableTimeSlots([], date);
                }
            } else {
                alert("Something went wrong");
            }
        });

    }


    /*  NEW CODE FOR RANDOM TECHNICIAN  */

    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */
    function unionTimeSlots(data, startdate) {
        const slots = {};
        let unionSchedule = [];
        const startTime = moment(moment(startdate).set({ hour: 6, minute: 0, second: 0 }));
        const endTime = moment(moment(startdate).set({ hour: 19, minute: 0, second: 0 }));

        data.forEach(item => {
            if (!slots[item.resourceId]) {
                slots[item.resourceId] = [];
            }

            if (item.className.trim() === 'in_active_class' || item.className.trim() === 'off_day_class') {
                slots[item.resourceId].push({
                    start: moment(item.start),
                    end: moment(item.end)
                });
            }
        });

        const availableSlots = [];

        for (const resourceId in slots) {
            let current = moment(startTime);
            availableSlots[resourceId] = [];

            while (current.isBefore(endTime)) {
                let nextSlot = moment(current).add(1, 'hour'); // Assuming 1-hour slots

                let isAvailable = true;
                slots[resourceId].forEach(bookedSlot => {
                    if (current.isBetween(bookedSlot.start, bookedSlot.end, null, '[)') ||
                        nextSlot.isBetween(bookedSlot.start, bookedSlot.end, null, '[)')) {
                        isAvailable = false;
                    }
                });

                if (isAvailable) {
                    availableSlots[resourceId].push({ start: current.format("YYYY-MM-DD HH:mm:ss"), end: nextSlot.format("YYYY-MM-DD HH:mm:ss") });
                }

                current = nextSlot;
            }
        }
 

        for (let staffId in availableSlots) {
            if (availableSlots[staffId].length === 0) {
                delete availableSlots[staffId];
            } else {
                availableSlots[staffId].forEach(slot => {
                    unionSchedule.push({ staffId: staffId, ...slot });
                });
            }
        }

        // setAvailableTime(unionSchedule);
        // const aIds = new Set(technicianList.map(item => item.id));
        const aIds = new Set(technicianList.filter(item => item != null).map(item => item.id));


        const filteredB = unionSchedule.filter(item => aIds.has(item.staffId));

 
        generateMissingSlots(filteredB);
    }

    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */

    function generateMissingSlots(slots) {
        let staffSlots = {};
        let generatedSlots = [];

        let uniqueSlots = [];
        let seenSlots = new Set();
        var counting = 1;

        // Organize slots by staff ID
        slots.forEach(slot => {
            if (!staffSlots[slot.staffId]) {
                staffSlots[slot.staffId] = new Set();
            }
            staffSlots[slot.staffId].add(slot.start);
        });

        let allTimeSlots = new Set(slots.map(slot => slot.start));

        // Generate missing slots for each staff
        Object.keys(staffSlots).forEach(staffId => {
            allTimeSlots.forEach(timeSlot => {
                if (staffSlots[staffId].has(timeSlot)) {
                    // Generate the missing slot for this staff
                    let newSlot = {
                        id: counting++,
                        staffId: staffId,
                        start: timeSlot,
                        end: moment(timeSlot).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss"),
                    };
                    generatedSlots.push(newSlot);
                }
            });
        });

        // Sort generated slots by start time
        generatedSlots.sort((a, b) => {
            let startTimeA = new Date(a.start);
            let startTimeB = new Date(b.start);
            return startTimeA - startTimeB;
        });

        generatedSlots.forEach(slot => {
            let slotKey = slot.start + '|' + slot.end;
            if (!seenSlots.has(slotKey)) {
                seenSlots.add(slotKey);
                uniqueSlots.push(slot);
            }
        });

        console.log("uniqueSlots", uniqueSlots); 
        // return uniqueSlots;
        setavailableTimeRandom(uniqueSlots);
    }

    const fetchAndProcessSlots = (date, zipcode) => { 
        getstaffslotdatabyzone(date, zipcode).then((res) => {
            if (res.code === 200) {
                if (res.data.status === 1) {
                    const apiResponse = res.data;
                    if (apiResponse.data.length > 0) {
                        unionTimeSlots(apiResponse.data, date);
                        setShowavailable(true);
                    } else {
                        unionTimeSlots([], date);
                        setShowavailable(true);
                    }
                }
            } else {
                alert("Something went wrong");
            }
        });
    };

    useEffect(() => {
        if (randomtech) {
            const date = moment(value).format("YYYY-MM-DD");
            setChoosedate(date);
            fetchAndProcessSlots(date, zipcode);
        }
    }, [randomtech, value]);
 

    useEffect(() => { 
        const date = moment(value).format("YYYY-MM-DD"); 
        fetchAndProcessSlots(date, zipcode);
    }, [chooseservice]);

 
    useEffect(() => {
        // Ensure technicianList is an array before calling filter
        if (Array.isArray(technicianList) && chooseservice) {
            const filteredStaff = technicianList.filter(person =>
                person.service_ids?.split(",").includes(chooseservice)
            );
            setTechnicianList(filteredStaff);
        }
    }, [chooseservice]);


    if (props.currentStep !== 2) {
        return null;
    }


    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */

    const getavailableTimeSlots = (bookingData, startdate) => {


        const startTime = moment(moment(startdate).set({ hour: 6, minute: 0, second: 0 }));
        const endTime = moment(moment(startdate).set({ hour: 19, minute: 0, second: 0 }));

        const offDaysData = [];

        var counting = 1;

        const availableTimeSlots = [];

        let currentTime = moment(moment(startdate).set({ hour: 6, minute: 0, second: 0 }));
        let currentTime2 = moment(moment(startdate).set({ hour: 7, minute: 0, second: 0 }));

        while (currentTime.isSameOrBefore(endTime)) {
            let isBooked = false;

            isBooked = bookingData.some((booking) => {
                var className = booking.className.trim();
                if (className == "off_day_class") {

                    var startdatearray = booking.start.split(" ");
                    var enddatearray = booking.end.split(" ");

                    var startdatearraylength = startdatearray.length;

                    if (startdatearraylength > 1) {
                        var bookingStart = moment(booking.start);
                        var bookingEnd = moment(moment(booking.end).add(1, 'hour'));
                    }
                    else {
                        var bookingStart = moment(booking.start + " 00:00:00").format("YYYY-MM-DD HH:mm:ss");
                        var bookingEnd = moment(moment(booking.end + " 23:59:59").format("YYYY-MM-DD HH:mm:ss").add(1, 'hour'));
                    }

                }
                else {
                    // var bookingStart = moment(moment(booking.start).add(expectedduration, 'minute'));
                    var bookingStart = moment(booking.start);
                    var bookingEnd = moment(moment(booking.end).add(1, 'hour'));
                }


                console.log("bookingStart", bookingStart);
                console.log("bookingEnd", bookingEnd);
                console.log("currentTime", currentTime.format("YYYY-MM-DD HH:mm:ss"));
                console.log("currentTime1", currentTime2.format("YYYY-MM-DD HH:mm:ss"));
                console.log("result", (currentTime.isSameOrAfter(bookingStart) && currentTime.isSameOrBefore(bookingEnd)) || (currentTime2.isAfter(bookingStart) && currentTime2.isBefore(bookingEnd)));

                console.log("---------------------------------------------------------------------------------------------------isSameOrAfter");
                if (expectedduration >= 60) {
                    return ((currentTime.isSameOrAfter(bookingStart) && currentTime.isBefore(bookingEnd)) || (currentTime2.isSameOrAfter(bookingStart) && currentTime2.isBefore(bookingEnd))
                    );
                } else {
                    return (
                        (currentTime.isSameOrAfter(bookingStart) && currentTime.isBefore(bookingEnd)) || (currentTime2.isAfter(bookingStart) && currentTime2.isBefore(bookingEnd))
                    );
                }

                // return (
                //     (currentTime.isSameOrAfter(bookingStart) && currentTime.isBefore(bookingEnd)) || (currentTime2.isAfter(bookingStart) && currentTime2.isBefore(bookingEnd))
                // );


            });

            // const isOffDay = offDaysData.some((offDay) => {
            //     const offDayStart = moment(offDay.start);
            //     const offDayEnd = moment(offDay.end);
            //     return (
            //         currentTime.isSameOrAfter(offDayStart) && currentTime.isBefore(offDayEnd)
            //     );
            // });
            const slotStart = currentTime.format("YYYY-MM-DD HH:mm:ss");
            const slotEnd = currentTime2.format("YYYY-MM-DD HH:mm:ss");
            console.log("slotStart", slotStart);
            console.log("slotEnd", slotEnd);
            console.log("isBooked", isBooked);


            // //now add expectedduration in minutes to get endtime of slot
            // var slotEndTime = moment(slotStart).add(expectedduration, 'minutes').format("YYYY-MM-DD HH:mm:ss");
            // slotEndTime = moment(slotEndTime).add(1, 'hour').format("YYYY-MM-DD HH:mm:ss");
            // console.log("slotEndTime", slotEndTime);


            // if (!isBooked && !isOffDay) {
            if (currentTime.isSameOrAfter(moment())) {
                if (!isBooked) {
                    availableTimeSlots.push({
                        id: counting++,
                        start: slotStart,
                        end: slotEnd,
                    });
                }
            }
            currentTime = currentTime.add(1, "hour");
            currentTime2 = currentTime2.add(1, "hour");
            // currentTime = currentTime.add(30, "minute");
        }
        console.log("availableTimeSlots", availableTimeSlots);
        setavailableTime(availableTimeSlots);

    }


    /* AVALIABLE BOOKING SLOTE */
    /* AVALIABLE BOOKING SLOTE */



    return (
        <>
            <div className="booking-form-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="step-count">
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    Technician
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
                        <div className="technician-list" style={{ display: randomtech ? "none" : "block" }}>
                            {technicianList && technicianList.map((item, index) => (

                                <Button className={`technician-profile-btn ${staffid === item.id ? 'active' : ''}`} onClick={(e) => {
                                    selectstaff(item.id);
                                }}>
                                    <div className="technician-profile">
                                        <div className="technician-profile-img">
                                            {item.image ? <img src={item.image} alt="Avatar" /> : <img src={Profile} alt="Avatar" />
                                            }
                                        </div>
                                        <div className="technician-profile-info">
                                            <div className="technician-profile-name">
                                                <h4>{item.fullname}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            ))}
                        </div>
                        <div className="technician-list">
                            <Form>
                                {['checkbox'].map((type) => (
                                    <div key={`default`} className="mb-3">
                                        <Form.Check
                                            type={type}
                                            id={`default`}
                                            defaultChecked={true}
                                            onChange={(e) => setRandomtech(e.target.checked)}
                                            label={`Select Random Technician`}
                                        />
                                    </div>
                                ))}
                            </Form>
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <div className="technician-list" style={{ display: randomtech ? "none" : "block" }}>
                            {technicianList && technicianList.map((item, index) => (
                                <Button
                                    className={`technician-profile-btn ${staffid === item.id ? 'active' : ''}`}
                                    onClick={() => selectstaff(item.id)}
                                >
                                    <div className="technician-profile">
                                        <div className="technician-profile-img">
                                            {item.image ? (
                                                <img src={item.image} alt="Avatar" />
                                            ) : (
                                                <img src={Profile} alt="Avatar" />
                                            )}
                                        </div>
                                        <div className="technician-profile-info">
                                            <div className="technician-profile-name">
                                                <h4>{item.fullname}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                            ))}
                        </div>
                        <div className="technician-list">
                            <Form>
                                <div className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id="random-tech"
                                        checked={randomtech}
                                        onChange={(e) => setRandomtech(e.target.checked)}
                                        label="Select Random Technician"
                                    />
                                </div>
                                <div className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id="choose-tech"
                                        checked={!randomtech}
                                        onChange={(e) => setRandomtech(!e.target.checked)}
                                        label="Choose Your Technician"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>




                </div>
                <div className="row booking-warrper-40" {
                    ...choosetechnician === 0 ? { style: { display: "none" } } : { style: { display: "block" } }
                }>
                    <div className="col-md-12" {...choosetechnician === 0 ? { style: { display: "none" } } : { style: { display: "block" } }}>
                        <div className="step-count">
                            <div className="step-count-inner">
                                <div className="Step-icon">
                                    <span><TbListCheck /></span>
                                </div>
                                <div className="step-count-inner-title">
                                    When would you like us to come?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="calender-box">
                                <Calendar minDate={new Date()} onChange={(e) => { onChange(e); getstaffavailability(e); }} value={value} />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12">
                            <div className="time-box">
                                <div className="time-box-inner">

                                    {/* <div className="time-list">
                                        {availableTime && availableTime.map((item, index) => (

                                            <Button className={`time-btn ${selectedSlotid === item.id ? "active" : ""}`} onClick={
                                                (e) => {
                                                    setChoosetime(item.start);
                                                    setSelectedSlotid(item.id);
                                                }
                                            } >
                                                <div className="time-btn-inner">
                                                    <div className="time-btn-inner-title">
                                                        {moment(item.start).format("hh:mm A")}
                                                    </div>
                                                </div>
                                            </Button>
                                        ))}
                                    </div>  */}

                                    {
                                        randomtech ? (
                                            <div className="time-list">
                                                {showavailable && availableTimerandom.map((item, index) => (

                                                    <Button className={`time-btn ${selectedSlotid === item.id ? "active" : ""}`} onClick={
                                                        (e) => {
                                                            setChoosetime(item.start);
                                                            setSelectedSlotid(item.id);
                                                            selectstaff(item.staffId);
                                                            setChoosetechnician(item.staffId);
                                                        }
                                                    } >
                                                        <div className="time-btn-inner">
                                                            <div className="time-btn-inner-title">
                                                                {moment(item.start).format("hh:mm A")}
                                                                {/* {moment(item.start).format("hh:mm A")} - {moment(item.end).format("hh:mm A")} */}
                                                            </div>
                                                        </div>
                                                    </Button>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="time-list">
                                                {availableTime && availableTime.map((item, index) => (

                                                    <Button className={`time-btn ${selectedSlotid === item.id ? "active" : ""}`} onClick={
                                                        (e) => {
                                                            setChoosetime(item.start);
                                                            setSelectedSlotid(item.id);
                                                        }
                                                    } >
                                                        <div className="time-btn-inner">
                                                            <div className="time-btn-inner-title">
                                                                {moment(item.start).format("hh:mm A")}
                                                            </div>
                                                        </div>
                                                    </Button>
                                                ))}
                                            </div>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Step2;
