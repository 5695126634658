import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import "./Timesheet.css";
import Informasi from "../Components/TimesheetDataTable/Informasi";
import BootstrapTable from 'react-bootstrap-table-next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import pagination from '../Components/TimesheetDataTable/pagination';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../Components/TimesheetDataTable/table.css';
import { FaRegEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { getTimeSheet } from "../Api/timesheetApi";
import { getDataTimeSheet } from "../Api/timesheetApi";
import { updatetimesheet } from "../Api/timesheetApi";
import { deletetimesheet } from "../Api/timesheetApi";
import { getActiveStaff } from "../Api/timesheetApi";
import { getDeactivateStaff, addtimesheetdata } from "../Api/timesheetApi";
import { Roller } from "react-awesome-spinners";
import { CSVLink } from 'react-csv';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import moment from 'moment';

function Timesheet() {

    const [show11, setShow11] = useState(false);

    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);
    const [selected, setSelected] = useState([]);
    const [TimesheetData, setTimesheetData] = useState([]);
    const [startTimes, setStartTimes] = useState([]);
    const [endTimes, setEndTimes] = useState([]);
    const [note, setNote] = useState([]);
    const [timesheetid, setTimesheetid] = useState([]);
    const [keywords, setKeywords] = useState('');
    const [ActiveStaff, setActiveStaff] = useState([]);
    const [DeactivateStaff, setDactivateStaff] = useState([]);
    var newDate = new Date();
    const endDate = new Date(newDate);
    endDate.setDate(endDate.getDate() - 7);
    const formattedStartDate = newDate.getFullYear() + "-" + ((newDate.getMonth() + 1) < 10 ? '0' : '') + (newDate.getMonth() + 1) + "-" + (newDate.getDate() < 10 ? '0' : '') + newDate.getDate();
    const formattedEndDate = endDate.getFullYear() + "-" + ((endDate.getMonth() + 1) < 10 ? '0' : '') + (endDate.getMonth() + 1) + "-" + (endDate.getDate() < 10 ? '0' : '') + endDate.getDate();
    const [StartDate, setStartDate] = useState(formattedEndDate);
    const [EndDate, setEndDate] = useState(formattedStartDate);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oid = urlParams.get('oid');

    const [BookingId, setBookingId] = useState(oid);
    // const [ActiveStaffChnage , setActiveStaffChnage] = useState(null);
    // const [DeactivateStaffChnage , setDeactivateStaffChnage] = useState(null);
    const [StaffId, setStaffId] = useState('');
    const [loading, setLoading] = useState(false)
    const [EditStaffId, setEditStaffId] = useState([]);
    const [EditBookingId, setEditBookingId] = useState([]);



    const getActiveStaffList = async () => {
        // setLoading(true);
        getActiveStaff().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                console.log("getActiveStaff", ApiResponse.data);
                setActiveStaff(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            // setLoading(false);
        })
    }

    const getdeactivateStaffList = () => {
        // setLoading(true);
        getDeactivateStaff().then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                console.log("getdeactivateStaffList", ApiResponse.data);
                setDactivateStaff(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const getTimeSheetData = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("staff_id", StaffId);
        formData.append("date1", StartDate);
        formData.append("date2", EndDate);
        formData.append("booking_id", BookingId);

        getTimeSheet(formData).then((res) => {
            if (res.code === 200) {
                const ApiResponse = res.data;
                setTimesheetData(ApiResponse.data);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    };



    useEffect(() => {
        getActiveStaffList()
        // getdeactivateStaffList()
        getTimeSheetData()
    }, [])

    useEffect(() => {
        // if (oid != null) {
        //     setBookingId(oid);
        // }
        getTimeSheetData()
    }, [oid]);


    const handleedit = (id, staff_id, order_id) => {
        handleShow11();
        const formData = new FormData();
        formData.append("id", id);
        getDataTimeSheet(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                console.log("getDataTimeSheet", ApiResponse);
                setStartTimes(ApiResponse.data.date_time);
                setEndTimes(ApiResponse.data.out_time);
                setNote(ApiResponse.data.note);
                setTimesheetid(ApiResponse.data.id);
                setEditStaffId(staff_id);
                setEditBookingId(order_id);
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const UpdateTimeSheetData = () => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("id", timesheetid);
        // const formattedinTime = startTimes.replace('T', ' ');
        // formData.append("date_time", formattedinTime);
        // const formattedOutTime = endTimes.replace('T', ' ');
        formData.append("date_time", moment(startTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("out_time", moment(endTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("note", note);
        formData.append("staff_id", EditStaffId);
        formData.append("booking_id", EditBookingId);

        updatetimesheet(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    alert("Timesheet Updated Successfully");
                    handleClose11();
                    getTimeSheetData();
                } else {
                    alert("Something went wrong");
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    const optionFormatter3 = (cell, row) => {
        return (
            <>
                {
                    row && row.timesheet_entry === "0" ? <span> No Timesheet entry available </span> : (<div className="button-group">
                        <Button className="Delete-Active-bttn me-2" onClick={() => handledelete(row.id)}><RiDeleteBin6Line /></Button>
                        <Button className="Edit-Active-bttn" onClick={() => handleedit(row.id, row.staff_id, row.booking_id)}><FaRegEdit /></Button>
                    </div>)
                }

            </>
        );
    }

    // useEffect(() => {

    //     //changes like date is 2023-07-26 but we need Sep-1-2023 format and out_time be like this and cleaning_seconds convert to hh:mm:ss
    //     const data = TimesheetData.map((item) => {

    //         const date = new Date(item.date_time);
    //         const date2 = new Date(item.out_time);
    //         const date3 = new Date(item.cleaning_seconds * 1000);
    //         const date4 = new Date(item.genral_time * 1000);
    //         const date5 = new Date(item.driving_seconds * 1000);
    //         const date6 = new Date(item.training_seconds * 1000);
    //         const date7 = new Date(item.team_booking_sec * 1000);
    //         const date8 = new Date(item.end_time * 1000);

    //         const date_time = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    //         const out_time = date2.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    //         const cleaning_seconds = date3.toLocaleTimeString();
    //         const genral_time = date4.toLocaleTimeString();
    //         const driving_seconds = date5.toLocaleTimeString();
    //         const training_seconds = date6.toLocaleTimeString();
    //         const team_booking_sec = date7.toLocaleTimeString();
    //         const end_time = date8.toLocaleTimeString();

    //         return {
    //             ...item,
    //             date_time,
    //             out_time,
    //             cleaning_seconds,
    //             genral_time,
    //             driving_seconds,
    //             training_seconds,
    //             team_booking_sec,
    //             end_time
    //         };
    //     }
    //     );

    //     setTimesheetData(data);
    // }, [TimesheetData]);


    const columns = [{
        dataField: 'date',
        text: 'START DATE/TIME',
        sort: true,
        footer: 'Total'
    },
    {
        dataField: 'out_time',
        text: 'STOP DATE/TIME',
        sort: true,
        footer: '-'
    },
    {

        dataField: 'clientname',
        text: 'CLIENT NAME',
        sort: true,
        footer: '-',
        formatter: (cell, row) => {
            const firstName = row.client_first_name || '';
            const lastName = row.client_last_name || '';
            const fullName = `${firstName} ${lastName}`.trim();
            return fullName || ' ';
        }

    },
    {
        dataField: 'booking_id',
        text: 'APPOINTMENT #',
        sort: true,
        footer: '-'
    },
    {
        dataField: 'staff_name',
        text: 'TECHNICIAN NAME',
        sort: true,
        footer: '-'
    },
    {
        dataField: 'cleaning_seconds',
        text: 'CLEANING TIME',
        sort: true,
        formatter: (cell, row) => {
            const totalseconds = row.cleaning_seconds;
            const hours = totalseconds / 3600;
            const formattedTime = hours.toFixed(2);
            return formattedTime;
        },
        footer: () => {
            if (TimesheetData) {
                // const totalSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.cleaning_seconds), 0);
                // const hours = Math.floor(totalSeconds / 3600);
                // const minutes = Math.floor((totalSeconds % 3600) / 60);
                // const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                // return formattedTime;
                const totalSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.cleaning_seconds), 0);
                const hours = totalSeconds / 3600;
                const formattedTime = hours.toFixed(2);
                return formattedTime;
            } else {
                return '00:00'; // Default value if TimesheetData is empty
            }
        }
    },
    {
        dataField: 'team_booking_sec',
        text: 'TOTAL TEAM TIME',
        sort: true,
        formatter: (cell, row) => {
            const totalsecondsteam = row.team_booking_sec;
            const hours = totalsecondsteam / 3600;
            const formattedTime = hours.toFixed(2);
            return formattedTime;
        },
        footer: () => {
            if (TimesheetData) {
                const totalSeconds = TimesheetData.reduce((acc, item) => {
                    const teamBookingSec = item.team_booking_sec ? parseFloat(item.team_booking_sec) : 0;
                    return acc + teamBookingSec;
                }, 0);
                const hours = totalSeconds / 3600;
                const formattedTime = hours.toFixed(2);
                return formattedTime;
            } else {
                return '00:00'; // Default value if TimesheetData is empty
            }
        }
    },
    {
        dataField: 'general_seconds',
        text: 'GENERAL TIME',
        sort: true,
        formatter: (cell, row) => {
            const totalsecondgeneral = row.general_seconds;
            const hours = totalsecondgeneral / 3600;
            const formattedTime = hours.toFixed(2);
            return formattedTime;
        },
        footer: () => {
            if (TimesheetData) {
                const totalSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.general_seconds), 0);
                const hours = totalSeconds / 3600;
                // const hours = Math.floor(totalSeconds / 3600);
                // const minutes = Math.floor((totalSeconds % 3600) / 60);
                // const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                // return formattedTime;
                const formattedTime = hours.toFixed(2);
                return formattedTime;
            } else {
                return '00:00'; // Default value if TimesheetData is empty
            }
        }
    },
    {
        dataField: 'driving_seconds',
        text: 'DRIVING TIME',
        sort: true,
        formatter: (cell, row) => {
            const totalseconddriving = row.driving_seconds;
            const hours = totalseconddriving / 3600;
            const formattedTime = hours.toFixed(2);
            return formattedTime;
        },
        footer: () => {
            if (TimesheetData) {
                const totalSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.driving_seconds), 0);
                const hours = totalSeconds / 3600;
                const formattedTime = hours.toFixed(2);
                return formattedTime;
                // const hours = Math.floor(totalSeconds / 3600);
                // const minutes = Math.floor((totalSeconds % 3600) / 60);
                // const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                // return formattedTime;
            } else {
                return '00:00'; // Default value if TimesheetData is empty
            }
        }
    },
    {
        dataField: 'training_seconds',
        text: 'TRAINING TIME',
        sort: true,
        formatter: (cell, row) => {
            const totalsecondtraining = row.training_seconds;
            const hours = totalsecondtraining / 3600;
            const formattedTime = hours.toFixed(2);
            return formattedTime;
        },
        footer: () => {
            if (TimesheetData) {
                const totalSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.training_seconds), 0);
                const hours = totalSeconds / 3600;
                const formattedTime = hours.toFixed(2);
                return formattedTime;
                // const hours = Math.floor(totalSeconds / 3600);
                // const minutes = Math.floor((totalSeconds % 3600) / 60);
                // const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                // return formattedTime;
            } else {
                return '00:00'; // Default value if TimesheetData is empty
            }
        }
    },
    {
        dataField: 'team_member_name',
        text: 'TEAM MEMBERS',
        sort: true
    },
    {
        dataField: 'billed_hours',
        text: 'BILLED HOURS',
        sort: true
    },
    {
        dataField: 'note',
        text: 'TIME SHEET NOTES',
        sort: true
    },
    {
        text: 'ACTION',
        isDummy: true,
        formatter: optionFormatter3
    }];

    // const data = informasiList;

    const handleSearch = (e) => {
        console.log(e.target);
    }

    const handledelete = (id) => {
        // setLoading(true);
        if (window.confirm("Are you sure you want to delete this?")) {
            const formData = new FormData();
            formData.append("id", id);
            deletetimesheet(formData).then((res) => {
                if (res.code === 200) {
                    // setLoading(false);
                    const ApiResponse = res.data;
                    if (ApiResponse.status === "success") {
                        alert("Timesheet Deleted Successfully");
                        getTimeSheetData();
                    } else {
                        alert("Something went wrong");
                    }
                } else {
                    alert("Something went wrong");
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    const handleChange = (e) => {
        setKeywords(e.target.value);
    }

    let totalCleaningSeconds = 0;
    if (TimesheetData) {
        totalCleaningSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.cleaning_seconds), 0);
    }
    const totalCleaningHours = Math.floor(totalCleaningSeconds / 3600);
    const totalCleaningMinutes = Math.floor((totalCleaningSeconds % 3600) / 60);
    const formattedTotalCleaningTime = `${totalCleaningHours.toString().padStart(2, '0')}:${totalCleaningMinutes.toString().padStart(2, '0')}`;

    let totalGeneralSeconds = 0;
    if (TimesheetData) {
        totalGeneralSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.general_seconds), 0);
    }
    const totalGeneralHours = Math.floor(totalGeneralSeconds / 3600);
    const totalGeneralMinutes = Math.floor((totalGeneralSeconds % 3600) / 60);
    const formattedTotalGeneralTime = `${totalGeneralHours.toString().padStart(2, '0')}:${totalGeneralMinutes.toString().padStart(2, '0')}`;

    let totalDrivingSeconds = 0;
    if (TimesheetData) {
        totalDrivingSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.driving_seconds), 0);
    }
    const totalDrivingHours = Math.floor(totalDrivingSeconds / 3600);
    const totalDrivingMinutes = Math.floor((totalDrivingSeconds % 3600) / 60);
    const formattedTotalDrivingTime = `${totalDrivingHours.toString().padStart(2, '0')}:${totalDrivingMinutes.toString().padStart(2, '0')}`;


    let totalTrainingSeconds = 0;
    if (TimesheetData) {
        totalTrainingSeconds = TimesheetData.reduce((acc, item) => acc + parseFloat(item.training_seconds), 0);
    }
    const totalTrainingHours = Math.floor(totalTrainingSeconds / 3600);
    const totalTrainingMinutes = Math.floor((totalTrainingSeconds % 3600) / 60);
    const formattedTotalTrainingTime = `${totalTrainingHours.toString().padStart(2, '0')}:${totalTrainingMinutes.toString().padStart(2, '0')}`;


    function calculateHours(timeString) {
        if (!timeString) return '0.00';
        const hours = timeString / 3600;
        const formattedTime = hours.toFixed(2);
        return formattedTime;
    }


    const csvReport = {
        data: TimesheetData.map(item => {
            return {
                date: item.date || '',
                out_time: item.out_time || '',
                clientname: `${(item.client_first_name || '')} ${(item.client_last_name || '')}`.trim(),
                appointment: item.booking_id || '',
                // staff_name: item.staff_name || '',
                staff_name: (item.staff_name || '').trim(),
                cleaning_seconds: calculateHours(item.cleaning_seconds),
                team_booking_sec: calculateHours(item.team_booking_sec),
                general_seconds: calculateHours(item.general_seconds),
                driving_seconds: calculateHours(item.driving_seconds),
                training_seconds: calculateHours(item.training_seconds),
                team_member_name: (item.team_member_name || '').trim(),
                billed_hours: item.billed_hours || '',
                note: item.note || '',
            };
        }),
        headers: [
            { label: "START DATE/TIME", key: "date" },
            { label: "STOP DATE/TIME", key: "out_time" },
            { label: "CLIENT NAME", key: "clientname" },
            { label: "APPOINTMENT #", key: "appointment" },
            { label: "TECHNICIAN NAME", key: "staff_name" },
            { label: "CLEANING TIME", key: "cleaning_seconds" },
            { label: "TOTAL TEAM TIME", key: "team_booking_sec" },
            { label: "GENERAL TIME", key: "general_seconds" },
            { label: "DRIVING TIME", key: "driving_seconds" },
            { label: "TRAINING TIME", key: "training_seconds" },
            { label: "TEAM MEMBERS", key: "team_member_name" },
            { label: "BILLED HOURS", key: "billed_hours" },
            { label: "TIME SHEET NOTES", key: "note" }
        ],
        filename: 'Timesheet.csv'
    };


    const MyDoc = () => (
        <Document>
            <Page>
                <p>Timesheet</p>
                <BootstrapTable bootstrap4 keyField='id' data={TimesheetData} columns={columns} pagination={pagination} />;
            </Page>
        </Document>
    );

    const excelReport = {
        data: TimesheetData,
        headers: [
            { label: "DATE", key: "booking_date_time" },
            { label: "CLIENT NAME", key: "clientname" },
            { label: "TECHNICIAN", key: "technician" },
            { label: "TECHNICIAN CLEANING TIME", key: "techniciancleaningtime" },
            { label: "TOTAL TIME", key: "totaltime" },
            { label: "GENERAL TIME", key: "generaltime" },
            { label: "TEAM MEMBERS", key: "teammembers" },
            { label: "BILLED HOURS", key: "billed_hours" },
            { label: "APPOINTMENT #", key: "appointment" },
            { label: "NOTES", key: "notes" },
            { label: "APT START", key: "apt_start" },
            { label: "APT END", key: "apt_end" },
        ],
        filename: 'Timesheet.xlsx'
    };



    const copyReport = {
        //only  copy data
        data: TimesheetData.map(item => {
            return {
                date: item.date || '',
                out_time: item.out_time || '',
                clientname: `${(item.client_first_name || '')} ${(item.client_last_name || '')}`.trim(),
                appointment: item.booking_id || '',
                staff_name: (item.staff_name || '').trim(),
                cleaning_seconds: calculateHours(item.cleaning_seconds),
                team_booking_sec: calculateHours(item.team_booking_sec),
                general_seconds: calculateHours(item.general_time),
                driving_seconds: calculateHours(item.driving_seconds),
                training_seconds: calculateHours(item.training_seconds),
                team_member_name: (item.team_member_name || '').trim(),
                billed_hours: item.billed_hours || '',
                note: item.note || '',
            };
        }),
        headers: [
            { label: "START DATE/TIME", key: "date" },
            { label: "STOP DATE/TIME", key: "out_time" },
            { label: "CLIENT NAME", key: "clientname" },
            { label: "APPOINTMENT #", key: "appointment" },
            { label: "TECHNICIAN NAME", key: "staff_name" },
            { label: "CLEANING TIME", key: "cleaning_seconds" },
            { label: "TOTAL TEAM TIME", key: "team_booking_sec" },
            { label: "GENERAL TIME", key: "general_seconds" },
            { label: "DRIVING TIME", key: "driving_seconds" },
            { label: "TRAINING TIME", key: "training_seconds" },
            { label: "TEAM MEMBERS", key: "team_member_name" },
            { label: "BILLED HOURS", key: "billed_hours" },
            { label: "TIME SHEET NOTES", key: "note" }
        ],
        filename: 'Timesheet.docx'
    };


    const renderFooter = () => {
        const data = TimesheetData;

        //add all values of amount column
        const totaltime = data.reduce((sum, row) => sum + row.totaltime, 0);
        const techniciancleaningtime = data.reduce((sum, row) => sum + row.techniciancleaningtime, 0);
        const generaltime = data.reduce((sum, row) => sum + row.generaltime, 0);

        const footerColumns = [
            { label: "TOTAL TIME", value: totaltime },
            { label: "TECHNICIAN CLEANING TIME", value: techniciancleaningtime },
            { label: "GENERAL TIME", value: generaltime },
        ];

        return (
            <tr>
                <td colSpan="3" className="text-right">Total</td>
                {footerColumns.map((item, index) => (
                    <td key={index}>{item.value}</td>
                ))}
            </tr>
        );
    };

    const enterytypedata = [
        {
            label: 'General time',
            value: 'general-time',
        },
        {
            label: 'Driving time',
            value: 'driving-time',
        },
        {
            label: 'Traning time',
            value: 'traning-time',
        },
    ];

    const [show111, setShow111] = useState(false);

    const handleClose111 = () => setShow111(false);
    const handleShow111 = () => setShow111(true);

    const [addstartTimes, setAddStartTimes] = useState('');
    const [addendTimes, setAddEndTimes] = useState('');
    const [addnote, setAddNote] = useState('');
    const [techid, setTechid] = useState('');
    const [entrytype, setEntrytype] = useState('');



    const addtimesheettech = () => {

        if (addstartTimes === '' || addendTimes === '' || addnote === '' || techid === '' || entrytype === '') {
            alert('All fields are required');
            return;
        }

        const formData = new FormData();
        // const formattedinTime = addstartTimes.replace('T', ' ');
        // formData.append("date_time", formattedinTime);
        // const formattedOutTime = addendTimes.replace('T', ' ');
        // formData.append("out_time", formattedOutTime);
        formData.append("date_time", moment(addstartTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("out_time", moment(addendTimes).format('YYYY-MM-DD HH:mm'));
        formData.append("note", addnote);
        formData.append("staff_id", techid);
        formData.append("booking_id", '');
        formData.append("entrytype", entrytype);


        addtimesheetdata(formData).then((res) => {
            if (res.code === 200) {
                // setLoading(false);
                const ApiResponse = res.data;
                if (ApiResponse.status === "success") {
                    alert("Timesheet added Successfully");
                    handleClose11();
                    getTimeSheetData();
                } else {
                    alert("Something went wrong");
                }
            } else {
                alert("Something went wrong");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setAddStartTimes('');
            setAddEndTimes('');
            setAddNote('');
            setTechid('');
            setEntrytype('');
            handleClose111();
        })
    }







    return (
        <div>
            {
                loading ? (
                    <div className="loading">
                        <Roller />
                    </div>
                ) : (<>
                    <div className="dashboard-container d-flex">
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="main-container">
                            <div className="main-page-body-content">
                                {/* <div className="main-page-body-content-header">
                                    <Header />
                                </div> */}
                                <div className="main-page-body-content-body">
                                    <div className="main-page-body-content-body-container">
                                        <div className="row">
                                            <div className="select-box-list">
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>From</Form.Label>
                                                        <Form.Control type="date" value={StartDate} onChange={(e) => {
                                                            setStartDate(e.target.value);
                                                        }
                                                        } placeholder="" />
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>To</Form.Label>
                                                        <Form.Control type="date" value={EndDate} onChange={
                                                            (e) => {

                                                                setEndDate(e.target.value);
                                                            }
                                                        } placeholder="" />
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Technician</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={
                                                            (e) => {
                                                                setStaffId(e.target.value);
                                                            }
                                                        }>
                                                            <option value="0">Select Technician</option>
                                                            {ActiveStaff.map((item, index) => (
                                                                <option value={item.id} style={{ color: item.tech_status === "0" ? 'green' : 'red' }} onChange={() => setStaffId(item.id)} selected={item.id === StaffId}>{item.tech_status === "0" ? 'Active - ' : 'Inactive - '}{item.fullname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Appointment Number</Form.Label>
                                                        <Form.Control type="text" value={BookingId} onChange={(e) => {
                                                            setBookingId(e.target.value);

                                                        }
                                                        } placeholder="" />
                                                    </Form.Group>
                                                </div>
                                                <div className="select-box-list-item">
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label className="w-100 mb-4"></Form.Label>
                                                        <button className="btn btn-primary downloads-btn-preview me-1" onClick={getTimeSheetData}>Submit</button>
                                                        {/* 
                                                        <Form.Select aria-label="Default select example" onChange={
                                                            (e) => {
                                                                setStaffId(e.target.value);
                                                            }
                                                        }>
                                                            <option value="0">Select Technician</option>
                                                            {DeactivateStaff.map((item, index) => (
                                                                <option value={item.id} onChange={() => setStaffId(item.id)} >{item.fullname}</option>
                                                            ))}
                                                        </Form.Select> */}

                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="pd-26">
                                            <div className="table-card ">
                                                <div className="card-body table-card-body">
                                                    <div className="table-card-header mb-4 d-flex justify-content-between">
                                                        <h5 className="card-title">Time Sheet</h5>
                                                        <div className="d-flex justify-content-end">
                                                            <div className="export-btn">

                                                                <button className="btn btn-primary downloads-btn-preview me-1" onClick={handleShow111}>Add Timesheet</button>

                                                                <CSVLink {...csvReport}>
                                                                    <button className="btn btn-primary downloads-btn-preview me-1">Export CSV</button>
                                                                </CSVLink>

                                                                {/* <PDFDownloadLink document={<MyDoc />} fileName="Timesheet.pdf">
                                                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className="btn btn-primary me-1 downloads-btn-preview">Export PDF</button>)}
                                                                </PDFDownloadLink> */}

                                                                {/* <CSVLink {...excelReport}>
                                                                    <button className="btn btn-primary me-1 downloads-btn-preview">Export Excel</button>
                                                                </CSVLink> */}

                                                                <CSVLink {...copyReport}>

                                                                    <button className="btn btn-primary downloads-btn-preview">Export Word</button>
                                                                </CSVLink>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-card-body-inner">
                                                        {/* Timesheet modal start here */}
                                                        <Modal show={show11} onHide={handleClose11} className="Sidebar-Modal-end">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Edit Time Sheet</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body className='custum-modal-body'>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Start Time</Form.Label>
                                                                            <Form.Control type="datetime-local" value={startTimes} onChange={
                                                                                (e) => {
                                                                                    setStartTimes(e.target.value);
                                                                                }
                                                                            } placeholder="" />
                                                                            <Form.Control type="hidden" value={timesheetid} placeholder="" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Stop Time</Form.Label>
                                                                            <Form.Control type="datetime-local" value={endTimes} onChange={
                                                                                (e) => {
                                                                                    setEndTimes(e.target.value);
                                                                                }
                                                                            } placeholder="" />

                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Add a Note</Form.Label>
                                                                            <Form.Control type="text" placeholder=" " value={note} onChange={
                                                                                (e) => {
                                                                                    setNote(e.target.value);
                                                                                }
                                                                            } as="textarea" rows={3} />
                                                                        </Form.Group>
                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <button className="Active-bttn btn" onClick={UpdateTimeSheetData}> Update </button>
                                                                <button className="Cancel-bttn btn" onClick={handleClose11}> Cancel </button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                        {/* modal End here */}


                                                        {/* Edit CRM modal start here */}
                                                        <Modal show={show111} onHide={handleClose111} className="Sidebar-Modal-end">
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Add Timesheet notes</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body className='custum-modal-body'>
                                                                <div className='row'>
                                                                    <div className='col-md-12'>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Start Time</Form.Label>
                                                                            <Form.Control type="datetime-local" value={addstartTimes} onChange={
                                                                                (e) => {
                                                                                    setAddStartTimes(e.target.value);
                                                                                }
                                                                            } placeholder="" />
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Stop Time</Form.Label>
                                                                            <Form.Control type="datetime-local" value={addendTimes} onChange={
                                                                                (e) => {
                                                                                    setAddEndTimes(e.target.value);
                                                                                }
                                                                            } placeholder="" />

                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Technician</Form.Label>
                                                                            <Form.Select aria-label="Default select example" onChange={
                                                                                (e) => {
                                                                                    setTechid(e.target.value);
                                                                                }
                                                                            }>
                                                                                <option value="0">Select Technician</option>
                                                                                {ActiveStaff.map((item, index) => (
                                                                                    <option key={index} style={{ color: item.tech_status === "0" ? 'green' : 'red' }} value={item.id}  >{item.tech_status === "0" ? 'Active - ' : 'Inactive - '}{item.fullname}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Entry Type</Form.Label>
                                                                            <Form.Select aria-label="Default select example" onChange={
                                                                                (e) => {
                                                                                    setEntrytype(e.target.value);
                                                                                }
                                                                            }>
                                                                                <option value="0">Select Technician</option>
                                                                                {enterytypedata.map((item, index) => (
                                                                                    <option key={index} value={item.value} >{item.label}</option>
                                                                                ))}
                                                                            </Form.Select>
                                                                        </Form.Group>

                                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                                            <Form.Label>Add a Note</Form.Label>
                                                                            <Form.Control type="text" placeholder=" " value={addnote} onChange={
                                                                                (e) => {
                                                                                    setAddNote(e.target.value);
                                                                                }
                                                                            } as="textarea" rows={3} />
                                                                        </Form.Group>

                                                                    </div>
                                                                </div>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button className="Cancel-bttn btn" onClick={handleClose111}>
                                                                    Close
                                                                </Button>
                                                                <Button className="Active-bttn btn" onClick={addtimesheettech}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                        {/* Edit CRM modal start here */}
                                                        <div className="animated fadeIn">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="card">
                                                                        <div className="card-header d-flex table-card-header">
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <BootstrapTable bootstrap4 keyField='id'
                                                                                data={TimesheetData}
                                                                                columns={columns}
                                                                                pagination={pagination}
                                                                                noDataIndication="No data available"
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </div>
    );
}

export default Timesheet;