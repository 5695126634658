import React, { useState, useContext, useEffect } from "react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Form from 'react-bootstrap/Form';
import '../../assets/css/Booking.css';
import 'react-notifications/lib/notifications.css';
import { SignUp, getuser } from "../../Api/frontbooingApi";
import { BookingContext } from "./BookingContext";
import { LoginContext } from "../../LoginContext";
import { TbListCheck } from "react-icons/tb";

const Step3 = props => {

    const [usertype, setUsertype, chooseservice, setChooseservice, choosesubservice, setChoosesubservice, chooseaddon, setChooseaddon, choosetechnician, setChoosetechnician, choosedate, setChoosedate, choosetime, setChoosetime, userDetails, setUserDetails, paymentDetails, setPaymentDetails, Totalpayment, setTotalpayment, totalDuration, setTotalDuration, appointmentNotes, setAppointmentNotes, servicename, setServicename, endchoosetime, setEndchoosetime, confirmpassword, setConfirmpassword, signUprequest, setSignUprequest] = useContext(BookingContext);
    const { LoggedInData, login, logout } = useContext(LoginContext);

    const [existingemail, setExistingemail] = useState("")
    const [existingpassword, setExistingpassword] = useState("")
    const [buttonState, setButtonState] = useState(0)

    if (LoggedInData != null && buttonState == 0) {
        setUserDetails(LoggedInData);
        setButtonState(1);
    } 

    const getUserDetails = () => {
        const formData = new FormData();
        formData.append("email", existingemail);
        formData.append("password", existingpassword);
        getuser(formData).then((res) => {
            if (res.code === 200) {
                if (res.data.status == '1') {
                    const apiResponse = res.data;
                    setUserDetails(apiResponse.data);
                    login(apiResponse.data);
                    setButtonState(1);
                }
                else {
                    NotificationManager.error('Invalid email and password', 'Close after 3000ms', 3000);
                }

            } else {
                alert("Something went wrong");
            }
        });
    };

    const states = [
        { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
        { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
        { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
        { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
        { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
        { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
        { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
        { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
        { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
        { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
        { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
        { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
        { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
        { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
        { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
        { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
        { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
        { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
        { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
        { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
        { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
        { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
        { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
        { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
        { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
        { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
        { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
        { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
        { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
        { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
        { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
        { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
        { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
        { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
        { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
        { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
        { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
    ];

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setUserDetails({ ...userDetails, state: event.target.value });
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setUserDetails({ ...userDetails, city: event.target.value });
    };

    // const cities = selectedState ? states.find((state) => state.name === selectedState).cities : [];



    const [validated, setValidated] = useState(false);


    useEffect(() => {
        if (signUprequest != 0) {
            // formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            // console.log("userDetails.user_email", userDetails.user_email);
            // console.log("userDetails", userDetails);
        }

    }, [signUprequest]);

    useEffect(() => {
        console.log("userDetails.emailexist", userDetails.emailexist);
    }, [userDetails]);




    if (props.currentStep !== 3) {
        return null;
    }


    return (
        <>
            <div className="booking-form-box">
                {
                    //check LoggedInData is null or not
                    LoggedInData == null ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="step-count">
                                    <div className="step-count-inner">
                                        <div className="Step-icon">
                                            <span><TbListCheck /></span>
                                        </div>
                                        <div className="step-count-inner-title">
                                            New Account Details
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {usertype == 0 ?
                                    <Form >
                                        <div className="booking-acount-detail-form">
                                            <div className="row booking-30">
                                                <div className="col-md-12 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="validationCustom01">
                                                        <Form.Label>Preferred Email</Form.Label>
                                                        <Form.Control type="email" value={userDetails.user_email || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, user_email: e.target.value });
                                                                // if(e.target.value == ""){
                                                                //     setUserDetails({ ...userDetails, emailexist: false });
                                                                // }
                                                            }
                                                        } placeholder="Enter email" className="booking-custom-input" />
                                                        {

                                                            userDetails.user_email == "" && signUprequest > 0 ?
                                                                <div class="form-error">Please provide a valid email.</div>

                                                                : null
                                                        }
                                                        {

                                                            userDetails.emailexist == true ?
                                                                <div class="form-error">Email is aleardy exist.</div>

                                                                : null
                                                        }

                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Preferred Password</Form.Label>
                                                        <Form.Control type="password" placeholder="********" value={userDetails.password || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, password: e.target.value });
                                                            }
                                                        }
                                                            className="booking-custom-input" />
                                                        {

                                                            userDetails.password == "" && signUprequest > 0 ?
                                                                <div class="form-error">Please provide a valid password.</div>

                                                                : null
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Form.Control type="password" placeholder="********" value={confirmpassword || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, confirmpassword: e.target.value });
                                                                setConfirmpassword(e.target.value);
                                                            }
                                                        }
                                                            className="booking-custom-input" />

                                                        {
                                                            confirmpassword != userDetails.password && confirmpassword != "" ?

                                                                <div class="form-error">Password and confirm password not match.</div>

                                                                : null
                                                        }



                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control type="name" value={userDetails.first_name || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, first_name: e.target.value });
                                                            }
                                                        } placeholder="" className="booking-custom-input" />
                                                    </Form.Group>
                                                    {

                                                        userDetails.first_name == "" && signUprequest > 0 ?
                                                            <div class="form-error">Please provide a valid first name.</div>

                                                            : null
                                                    }
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="name" placeholder="" value={userDetails.last_name || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, last_name: e.target.value });
                                                            }
                                                        } className="booking-custom-input" />
                                                    </Form.Group>
                                                    {

                                                        userDetails.last_name == "" && signUprequest > 0 ?
                                                            <div class="form-error">Please provide a valid last name.</div>

                                                            : null
                                                    }
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control type="number" value={userDetails.phone || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, phone: e.target.value });
                                                            }
                                                        } placeholder="" className="booking-custom-input" />
                                                    </Form.Group>
                                                    {

                                                        userDetails.phone == "" && signUprequest > 0 ?
                                                            <div class="form-error">Please provide a valid phone.</div>

                                                            : null
                                                    }
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Zip Code</Form.Label>
                                                        <Form.Control type="number" placeholder="" value={userDetails.zip || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, zip: e.target.value });
                                                            }
                                                        } className="booking-custom-input" />
                                                    </Form.Group>
                                                    {

                                                        userDetails.zip == "" && signUprequest > 0 ?
                                                            <div class="form-error">Please provide a valid zip.</div>

                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Street Address</Form.Label>
                                                        <Form.Control type="text" placeholder="" value={userDetails.address || ""} onChange={
                                                            (e) => {
                                                                setUserDetails({ ...userDetails, address: e.target.value });
                                                            }
                                                        } className="booking-custom-input" />
                                                        {

                                                            userDetails.address == "" && signUprequest > 0 ?
                                                                <div class="form-error">Please provide a address.</div>

                                                                : null
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group
                                                        className="booking-custom-lable"
                                                        controlId="validationCustom010"
                                                    >
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            className="custom-select-box"
                                                            onChange={(e) => {
                                                                console.log(e.target.value);
                                                                handleStateChange(e);
                                                            }}
                                                        >
                                                            <option>Select State</option>

                                                            {states.map((state) => (
                                                                <option value={state.stateCode}>{state.name}</option>
                                                            ))}

                                                        </Form.Select>
                                                        {

                                                            userDetails.state == "" && signUprequest > 0 ?
                                                                <div class="form-error">Please provide a valid state.</div>

                                                                : null
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Group
                                                        className="booking-custom-lable"
                                                        controlId="validationCustom011"
                                                        onChange={(e) => {
                                                            handleCityChange(e);
                                                        }}
                                                    >
                                                        <Form.Label>City</Form.Label>
                                                        {/* <Form.Select
                                                            required
                                                            aria-label="Default select example"
                                                            className="custom-select-box"
                                                        >
                                                            <option>Select City</option>
                                                            {cities.map((city) => (
                                                                <option key={city} value={city}>
                                                                    {city}
                                                                </option>
                                                            ))}

                                                        </Form.Select> */}
                                                        <Form.Control type="text" placeholder="" value={userDetails.city || ""} onChange={ (e) => { setUserDetails({ ...userDetails, city: e.target.value }); } } className="booking-custom-input" />
                                                    </Form.Group>
                                                    {

                                                        userDetails.city == "" && signUprequest > 0 ?
                                                            <div class="form-error">Please provide a valid city.</div>

                                                            : null
                                                    }
                                                </div>
                                                {/* <div className="col-md-12 mb-3">
                                                <div className="booking-custom-lable">
                                                    <button className="wizard-btn btn btn-primary d-flex float-right" style={{ "float": "right", }} onClick={UserSignUp}>SignUp</button>
                                                </div>
                                            </div> */}
                                                <div className="col-md-12 mb-3">
                                                    <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                        <Form.Label>Appointment Notes</Form.Label>
                                                        <Form.Control type="text" placeholder="" value={appointmentNotes || ""} onChange={
                                                            (e) => {
                                                                setAppointmentNotes(e.target.value);
                                                            }
                                                        } className="booking-custom-input" />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                    :
                                    <div className="booking-acount-detail-form">
                                        <div className="row booking-30">
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" onChange={(e) => { setExistingemail(e.target.value) }} value={existingemail} className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                                    <Form.Label>Preferred Password</Form.Label>
                                                    <Form.Control type="password" placeholder="********" onChange={(e) => { setExistingpassword(e.target.value) }} value={existingpassword} className="booking-custom-input" />
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="booking-custom-lable">
                                                    <button className="wizard-btn btn btn-primary d-flex float-right" style={{ "float": "right", }} onClick={getUserDetails}>Login</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div className="row Service-details-box-inner-title">
                            <div className="col-md-12 mb-3">
                                <Form.Group className="booking-custom-lable" controlId="formBasicEmail">
                                    <Form.Label>Appointment Notes</Form.Label>
                                    <Form.Control type="text" placeholder="" value={appointmentNotes || ""} onChange={
                                        (e) => {
                                            setAppointmentNotes(e.target.value);
                                        }
                                    } className="booking-custom-input" />
                                </Form.Group>
                            </div>
                        </div>
                }
            </div>
            <NotificationContainer />
        </>

    );

};

export default Step3;
